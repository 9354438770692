import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-height: 1200px) and (min-width: 700px), (max-width: 360px) {
    height: auto;
    padding-top: var(--nav-height);
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 5px;
    color: var(--slate);
    line-height: 0.9;
  }

  h4 {
    margin-top: 50px;
    color: var(--black);
    line-height: 0.4;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const Padding = (
    <>
      <p>
      </p>
      <p>
      </p>
      <p>
      </p>
      <p>
      </p>
    </>
  );
  const Intro = (
    <>
      <h3 className="big-heading">Hi,</h3>
      <p>
        I’m Shehryar and I created Isa to provide emotional support and empowerment. My first priority is to help those who would struggle to afford traditional mental health services.
      </p>
      <p>
        (You can access Isa here: <a href="https://isa.miriamtherapy.org/">isa.miriamtherapy.org</a>)
      </p>
      <p>
        Currently, I am covering Isa's setup and maintenance expenses to ensure that it remains free for everyone. Soon I will need a more sustainable funding structure. This will likely involve donations (<a href="https://www.patreon.com/MiriamTherapy">our Patreon link</a> - we're forever grateful) or a discriminative pricing model where those who can afford it pay while those who can't have access for free.
      </p>
      <p>
        Since its launch in mid-February 2023, maintaining Isa has been a significant undertaking. I have a full time job outside of this, but thankfully, my wonderful friend Aditya has joined the project, allowing me to take the occasional break. Despite being a small team, we are committed to ensuring that Isa continues to offer top-notch emotional support and empowerment - remaining at the forefront of AI based mental health solutions.
      </p>
    </>
  );
  const About = (
    <>
      <h4 className="medium-heading">About Isa,</h4>
      <p>
        Isa was initially a fun art project that I created in January for my friends to experience the remarkable advancements in language models. However, it soon surpassed my expectations and went beyond my immediate circle, with people finding it immensely beneficial for connection, advice, and empathy.
      </p>
      <p>
        It's been such a fulfilling experience to receive gratitude from individuals who may have struggled to afford conventional mental health services. This project has become the most meaningful way for Aditya and me to have a positive impact on the world, giving us both a sense of purpose.
      </p>
      <p>
        We firmly believe that this project can bring a significant shift to the mental health space. We envision a future where everyone has an exceptional counselor, better than any human, at their fingertips. An advisor and friend who can assist them in exploring and understanding themselves better, in both challenging and positive times.
      </p>
      <p>
        Currently, we are tirelessly working on enhancing Isa's conversational skills, allowing users to send voice notes, personalizing Isa directly for your goals, enabling reminders, better supporting journaling and much more. We are burning the midnight oil and will keep you posted on the updates here as they come to fruition.
      </p>
    </>
  );
  const Privacy = (
    <>
      <h4 className="medium-heading">Privacy,</h4>
      <p>
        Although we're not yet an established product, we value your privacy and consider it a top priority. Our guiding principle is to handle your data with the same level of care and respect that we would expect for our own information. Infact, we and many of our friends use Isa and share personal details with him. We take the responsibility of protecting this data very seriously.
      </p>
      <p>
        To minimize the amount of data we collect and keep it anonymous, we have implemented the following measures:
        <ul>
          <li>We delete all conversations after a month.</li>
          <li>By simply texting "/reset", you can delete everything about you at any time.</li>
          <li>We encrypt your WhatsApp contact information, and only Shehryar has access to the decryption key.</li>
          <li>Your first name is the only identifier in stored conversations. Isa pulls this from your WhatsApp profile to make the conversation more natural. By April 20th, we will provide functionality to remove your name from the conversation and replace it with a psuodonym</li>
        </ul>
      </p>
      <p>And, even though this really shouldn’t need pointing out, we do not sell your data. We believe there is more than enough business value in helping and empowering you directly.</p>
    </>
  );
  const Feedback = (
    <>
    <h4 className="medium-heading">Feedback,</h4>
    <p>
      Isa is so new. We’re still learning and adapting and there’s a lot to improve.
    </p>
    <p>
      Talking to users has been the main way Isa’s development is guided. We would ACTUALLY be extremely grateful if you reached out. </p>
    <p>
      Here are our personal emails and each week we will be in this online meeting room for an hour, please come and talk to us:
    </p>
    <ul>
      <li>Aditya: <a href = "mailto: adityarajan.ar@gmail.com">adityarajan.ar@gmail.com</a></li>
      <li>Shehryar: <a href = "mailto: shehryarsaroya@gmail.com">shehryarsaroya@gmail.com</a></li>
      <li><a href="https://meet.google.com/dcg-oked-hnu">Online Room Link - </a> <br />Every Sunday 9am PT, 12pm EST, 5pm GMT, for one hour</li>
    </ul>
    <p>
      And if you prefer to provide feedback through a form, we also have that: <a href="https://forms.gle/Dxq5fFMPzfiAAG3L7">https://forms.gle/Dxq5fFMPzfiAAG3L7</a>
    </p>
    </>
  );
  const Regards = (
    <>
      <p>
        Warmest Wishes, <br/>
        Shehryar and Aditya
      </p>
    </>
  );

  const items = [Padding, Intro, About, Privacy, Feedback, Regards];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
